// ChooseLocation.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from 'sweetalert2'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';

const libraries = ['places']; 
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Flypro = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenManage, setIsOpenManage] = React.useState(false);

  const [customers, setCustomers] = useState([]); // État pour stocker les données des clients
  const [zones, setZones] = useState([]); // État pour stocker les données des clients
  const [loading, setLoading] = useState(true);   // Pour afficher un état de chargement
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(0); // Page actuelle
  const [totalPages, setTotalPages] = useState(1);   // Total des pages
  const limit = 100; // Nombre de clients par page
  const [formData, setFormData] = useState({
    civility: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    birthdate: "",
    street: '',
    city: '',
    zipcode: '',
    type: "pro"
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk',
    libraries,
  });
  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };
  // Fonction pour mettre à jour les valeurs du formulaire
  const handleChange = (e) => {
    console.log(e)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street: '',
      city: '',
      zipcode: '',
    };

    for (const component of addressComponents) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          newAddress.street = `${component.long_name} ${newAddress.street}`;
          break;
        case 'route':
          newAddress.street = `${newAddress.street} ${component.long_name}`;
          break;
        case 'locality':
          newAddress.city = component.long_name;
          break;
        case 'postal_code':
          newAddress.zipcode = component.long_name;
          break;
        default:
          break;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      street: newAddress.street,
      city: newAddress.city,
      zipcode: newAddress.zipcode,
    }));
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();

    // Envoyer les données du formulaire au serveur via Axios
    axiosPrivate
      .post(endpoints.invite, formData)
      .then((response) => {
        if(response.data.status == false){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Une erreur est survenu! "+response.data.message,
            
          });

        }
          
        closeModal();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Compte crée",
          showConfirmButton: false,
          timer: 1500
        });
        console.log("Form submitted successfully:", response.data);
        fetchCustomers();
      })
      .catch((error) => {
        // Gérer les erreurs lors de l'envoi
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu! "+error,
          
        });
        console.error("Error submitting form:", error);
      });
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosPrivate.get(
        `${endpoints.getAdminUser}/?role=ROLE_PRO&page=${currentPage+1}&limit=${limit}`
      ); // L'URL inclut la page et la limite

      setCustomers(response.data.users); // Assurez-vous que 'customers' correspond aux données renvoyées par votre API
      setTotalPages(response.data.totalPages || 2); // Le nombre total de pages si disponible dans la réponse
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors du chargement des clients:", error);
      setLoading(false);
    }
  };

  const fetchZone = async () => {
    try {
      const response = await axiosPrivate.get(
        `${endpoints.getZone}`
      ); // L'URL inclut la page et la limite

      setZones(response.data.data); // Assurez-vous que 'customers' correspond aux données renvoyées par votre API
      // Le nombre total de pages si disponible dans la réponse
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors du chargement des zones:", error);
      setLoading(false);
    }
  };

   // Fonction pour calculer la date minimale (18 ans avant aujourd'hui)
   const calculateMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 18, // Soustraire 18 ans à l'année actuelle
      today.getMonth(),
      today.getDate()
    );
    return minDate.toISOString().split("T")[0]; // Formater la date en YYYY-MM-DD
  };
  // Requête API pour obtenir les clients avec pagination
  useEffect(() => {
    
fetchZone()
    fetchCustomers();
  }, [currentPage]); // Recharger les données à chaque changement de page

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }};

  function openModal() {
    setIsOpen(true);
  }

  function openModalManage() {
    setIsOpenManage(true);
  }

  function afterOpenModal() {

  }

  function afterOpenModalManage() {

  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalManage() {
    setIsOpenManage(false);
  }

  function closeModal() {
    setFormData({
      civility: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      zipcode: "",
      type: "client"
    });
    setIsOpen(false);
  }
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;
  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search by City or Team/Zone..."
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <div className="select">
              <select>
                <option value="">Name</option>
                <option value="Name">name</option>
              </select>
            </div>
            <div className="select">
              <select>
                <option value="">Exact Search</option>
                <option value="Name">Exact Search</option>
              </select>
            </div>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Ajouter un Flypro
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Image</th>
                <th>Username</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Therapist score</th>
                <th>Team</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {customers?.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>
                      <img src={image} alt="FlySpa" />
                    </td>
                    <td>{customer?.firstname} </td>
                    <td>{customer?.lastname}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.email}</td>
                    <td>score</td>
                    <td>{customer?.zone?.name}</td>
                    <td className={customer?.status?.toLowerCase()}>
                      {customer.status}
                    </td>
                    <td>
                      <a onClick={openModalManage} className="edit">
                        &#8942;
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">Aucun client trouvé.</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className="pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className="pagination-button"
            >
              Précédent
            </button>
            <span>
              Page {currentPage + 1} sur {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage >= totalPages - 1}
              className="pagination-button"
            >
              Suivant
            </button>
          </div> */}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <button onClick={closeModal}>close</button>
          <h2>Ajouter un Flypro</h2>
          <p>Si vous avez besoin de plus d'informations, veuillez consulter </p>
          <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-choix">
              <div className="form-radio">
                <input type="radio" name="civility" value="madame" id="madame" onChange={handleChange} />
                <label for="madame">Madame</label>
              </div>
              <div className="form-radio">
                <input type="radio" name="civility" value="monsieur" id="monsieur" onChange={handleChange} />
                <label for="monsieur">Monsieur</label>
              </div>
            </div>

            <div className="form-text">
              <label>Prénom *</label>
              <input type="text" name="firstname" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Nom *</label>
              <input type="text" name="lastname" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Numéro de téléphone portable *</label>
              <PhoneInput
            defaultCountry="ch"
            type="tel"
            name="phone"
            value={formData.phone}
            disableCountryGuess="true"
            //hideDropdown="true"
            forceDialCode="true"
            //onChange={handlePhoneChange}
            onChange={(phone) => handlePhoneChange(phone)}
            required
            />
            </div>

            <div className="form-text">
              <label>Adresse email</label>
              <input type="email" name="email" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Adresse</label>
              <Autocomplete
        apiKey="AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514"
        onPlaceSelected={handlePlaceSelect}
        options={{
          types: ['address'],
          componentRestrictions: { country: 'CH' },
        }}
        style={{ width: '100%', padding: '0 15px', height:'60px', border:'0', borderRadius:'20px', boxShadow:'0px 8px 50px 0px rgba(0, 0, 0, 0.06)' }}
      />
            </div>

            <div className="form-select">
              <label>Therapist zone</label>
              <div className="select">
                <select>
                  <option value="">Therapist zone</option>
                  {zones.map((zone) => (
                    <option value={zone?.id}>{zone?.name}</option>
                  ))

                  }
                  
                </select>
              </div>
            </div>

            <div className="form-select">
              <label>Gestionnaire</label>
              <div className="select">
                <select>
                  <option value="">Exact Search</option>
                  <option value="Name">Exact Search</option>
                </select>
              </div>
            </div>

            <button type="submit" className="btn-submit">
              Créer le compte
            </button>
          </form>
          </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenManage}
        onAfterOpen={afterOpenModalManage}
        onRequestClose={closeModalManage}
        style={customStyles}
        contentLabel="Example Modal"
      >
      <div className="bloc-manage-ther">
        <h2>Manage Therapists</h2>
        <p>Currently Managed Therapists</p>
        <form>
        <ul>
          <li>
            <div className="image"><img src={image} alt="FlySpa" /></div>
            <div className="id">72789016</div>
            <div className="username">Radija khther</div>
            <div className="lieu">Genéve</div>
            <div className="action">
              <input type="checkbox" id="actif" />
              <label htmlFor="actif"></label>
            </div>
          </li>
          <li>
            <div className="image"><img src={image} alt="FlySpa" /></div>
            <div className="id">72789016</div>
            <div className="username">Radija khther</div>
            <div className="lieu">Genéve</div>
            <div className="action">
              <input type="checkbox" id="actif1" />
              <label htmlFor="actif1"></label>
            </div>
          </li>
          <li>
            <div className="image"><img src={image} alt="FlySpa" /></div>
            <div className="id">72789016</div>
            <div className="username">Radija khther</div>
            <div className="lieu">Genéve</div>
            <div className="action">
              <input type="checkbox" id="actif2" />
              <label htmlFor="actif2"></label>
            </div>
          </li>
        </ul>

        <p>Available Therapists </p>
        <div className="bloc-search">
          <input type="text" className="form-text" placeholder="Search by Name" />
          <input type="submit" className="form-submit" value="Search" />
        </div>
        <ul>
          <li>
            <div className="image"><img src={image} alt="FlySpa" /></div>
            <div className="id">72789016</div>
            <div className="username">Radija khther</div>
            <div className="lieu">Genéve</div>
            <div className="action">
              <a href="#" className="btn-add">Add</a>
            </div>
          </li>
          <li>
            <div className="image"><img src={image} alt="FlySpa" /></div>
            <div className="id">72789016</div>
            <div className="username">Radija khther</div>
            <div className="lieu">Genéve</div>
            <div className="action">
              <a href="#" className="btn-add">Add</a>
            </div>
          </li>
          <li>
            <div className="image"><img src={image} alt="FlySpa" /></div>
            <div className="id">72789016</div>
            <div className="username">Radija khther</div>
            <div className="lieu">Genéve</div>
            <div className="action">
              <a href="#" className="btn-add">Add</a>
            </div>
          </li>
        </ul>
        <button className="btn-save">Save Changes</button>
        </form>
      </div>
      </Modal>
    </>
  );
};

export default Flypro;
