import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import useLogout from '../hooks/useLogout';

const MenuHeader = () => {
  const location = useLocation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();

    const signOut = async () => {
         await logout();
        navigate('/login');
    }


  const getMenuItem = () => {
    switch (location.pathname) {
      case '/':
        return 'Dashboard';      
      case '/booking':
        return 'Booking';
      case '/customer':
        return 'Customer';
      case '/flypro':
        return 'FlyPRO';
      case '/calendar':
        return 'Calendar Management';
      case '/user':
        return 'User';
      case '/products':
        return 'Domain & Products';
      case '/hotels':
        return 'Hotels';
      case '/notification':
        return 'Notification';
      case '/promotion':
        return 'Promotion';
      case '/statistic':
        return 'Statistic';
      case '/Management':
        return 'Setting';
      default:
        return 'Home';
    }
  };

  const handleMenuClick = () => {
    setIsSubMenuOpen((prev) => !prev);
  };

  return (
    <div className="menu-header">
      <div className="container">
        <a href="#" className="logo">Flyspa</a>
        <div className="menu">
          <div className="menu-left">
            <ul>
              <li><a href="#">{getMenuItem()}</a></li>
            </ul>
          </div>
          <div className="menu-right">
            <ul>
              <li><a href="#" className="notif"><span>3</span></a></li>
              <li><a href="#" className="account">SQ</a></li>
              <li className="parent">
                <a 
                  href="#" 
                  className="burger" 
                  onClick={handleMenuClick}
                >
                </a>
                {isSubMenuOpen && (
                  <ul className="sub-menu">
                    <li><a href="/">Dashboard</a></li>
                    <li><a href="#">Booking</a></li>
                    <li><a href="/customer">Customer</a></li>
                    <li><a href="/flypro">FlyPRO</a></li>
                    <li><a href="/calendar">Calendar Mgnt</a></li>
                    <li><a href="/user">User</a></li>
                    <li><a href="/products">Domain & Products</a></li>
                    <li><a href="/hotels">Hotels</a></li>
                    <li><a href="/notification">Notification</a></li>
                    <li><a href="/promotion">Promotion</a></li>
                    <li><a href="#">Statistic</a></li>
                    <li><a href="/Management">Setting</a></li>
                    <li><a href="#" onClick={signOut}>Logout</a></li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuHeader;
