import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { format, addDays } from 'date-fns';
import image from "../assets/images/profil-picto.png";
import arrowlineleft from "../assets/icons/ArrowLineLeft-s.svg";
import ArrowLineRight from "../assets/icons/ArrowLineRight-s.svg";
import MenuHeader from "./MenuHeader";
import Modal from "react-modal";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { endpoints } from '../utils/utils';

const Container = styled.div`
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  margin-left: 30px;
  margin-top: -80px;
`;

const Sidebar = styled.div`
    top: 145px;
    position: relative;
    width: 30%;
`;

const CalendarContainer = styled.div`
  flex: 1;
  padding: 20px;
  position: relative;
`;

const Header = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    top: 35px;
    left: 30px;
`;

const TimeSlots = styled.div`
  display: grid;
  grid-template-columns: repeat(840, 1fr);
  position: relative;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  background-color: #fff;
  height: 47px;
  padding: 0px 25px;
`;

const TimeSlot = styled.div`
  background-color: ${({ $status }) => {
    if ($status === 'available') return '#96ca63';
    if ($status === 'free') return '#8FA9B9';
    if ($status === 'booked') return '#FF0000';
    if ($status === 'overtime') return '#FAEF9B';
    return 'transparent';
  }}; 
  margin-bottom: 15px;
  top: 25px !important;
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 93px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 8px;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #fff;
  font-family: Inter;
`;

const HourLabel = styled.div`
  width: 50px;
    position: relative;
    font-size: 11px;
    line-height: 16px;
    font-family: Inter;
    color: rgba(0, 0, 0, 0.4);
    text-align: left;
    display: inline-block;
    height: 17px; 
    top: 15px;
    left: 0px;
`;

const TimeText = styled.div`
  color: ${({ status }) => (status === 'overtime' ? 'black' : 'white')};
  font-size: 14px;
  margin-bottom: 5px;
`;

const DurationText = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 12px;
  color: ${({ status }) => (status === 'overtime' ? 'black' : 'white')};
  font-family: Inter;
  margin: 0;
  font-weight: 900;
  font-family: Lato;
`;

const CurrentTimeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: ${({ $left }) => $left}%;
  height: 80vh;
  width: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 11;
`;

const CurrentTimeIndicatorLine = styled.div`
  width: 2px;
  background-color: black;
  height: 100%;
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: -70px;
  background-color: black;
  color: white;
  padding: 5px 8px;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
  font-family: Inter, sans-serif;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  ${CurrentTimeWrapper}:hover & {
    display: block;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const generateHours = (startHour, endHour, interval) => {
  const hoursArray = [];
  for (let h = startHour; h <= endHour; h++) {
    for (let m = 0; m < 60; m += interval) {
      const hour = h % 24;
      const formattedHour = `${hour % 12 === 0 ? 12 : hour % 12}:${String(m).padStart(2, '0')} ${h >= 12 ? 'PM' : 'AM'}`;
      hoursArray.push(formattedHour);
    }
  }
  return hoursArray;
};

const hours = generateHours(8, 21, 1);

const calculateDuration = (start, end) => {
  const parseTime = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hour, minute] = time.split(':').map(Number);
    if (modifier === 'PM' && hour !== 12) hour += 12;
    if (modifier === 'AM' && hour === 12) hour = 0;
    return hour * 60 + minute;
  };

  const startTotalMinutes = parseTime(start);
  const endTotalMinutes = parseTime(end);

  const durationMinutes = endTotalMinutes - startTotalMinutes;
  const totalMinutes = durationMinutes >= 0 ? durationMinutes : (24 * 60) + durationMinutes;

  const durationHours = Math.floor(totalMinutes / 60);
  const durationRemMinutes = totalMinutes % 60;

  return `${durationHours}:${String(durationRemMinutes).padStart(2, '0')}`;
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setMessage("");
    setFile(null);
  };
  const handleImport = async () => {
    if (!file) {
      setMessage("Please select a file before importing.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosPrivate.post(endpoints.importCalender, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMessage(response.data.message || "File imported successfully.");
      updateCalendar();
    } catch (error) {
      setMessage(
        error.response?.data?.message || "An error occurred during import."
      );
    }
  };

  const handleDownload = async () => {
    try {
      
      const response = await axiosPrivate.get(endpoints.getCalendarFile, {
        responseType: "blob", 
      });

     
      const url = window.URL.createObjectURL(new Blob([response.data]));

     
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloaded_file.xlsx"); 
      document.body.appendChild(link);
      link.click();

      
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors du téléchargement :", error);
      alert("Le téléchargement a échoué.");
    }
  };

  const fetchCalendarData = async (date) => {
    try {
      const response = await axiosPrivate.post(
        endpoints.getCalendarByDate,
        { date }, 

      );

      
      console.log(response.data)
      return response.data;

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };

  const fetchAvailibility = async (pro) => {
    try {
      const response = await axiosPrivate.post(
        endpoints.getAvailibility,
        { date: currentDate, pro }, 

      );

      console.log(response.data)
      return response.data;

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };

  const updateCalendar = async () => {
    try {
      const calendarData = await fetchCalendarData(currentDate);

      const groupedData = calendarData.reduce((acc, item) => {
        
        const therapistEmail = item.therapist.email;
        const therapistFirstName = item.therapist.firstname;
        const therapistLastName = item.therapist.lastname;
        const therapistCity = item.therapist.city;
        const therapistZipCode = item.therapist.zipcode;

        if (!acc[therapistEmail]) {
          acc[therapistEmail] = {
            therapist: therapistEmail,
            firstname: therapistFirstName,
            lastname: therapistLastName,
            zipcode: therapistZipCode,
            city: therapistCity,
            booked: null, 
            slots: [],
          };
        }

        item.slot.forEach(slot => {
          acc[therapistEmail].slots.push([
            slot.formattedStartTime,
            slot.formattedEndTime,
            slot.status,
          ]);
        });

        return acc;
      }, {});

      const therapistEmails = Object.keys(groupedData);
     
      
      const adjustAndSplitTimeSlots = (slots) => {
        const adjustedSlots = [];
        let lastEndTime = null;
        const restOfTimeAvailable = [];
        const restOfTimeOver = [];

        const bookedSlots = slots.filter(slot => slot[2] === 'booked');
        if(bookedSlots.length > 0){
        bookedSlots.forEach((booked, index) => {
          const [startBookedTime, endBookedTime, statusBooked] = booked;

          slots.forEach((slot, index) => {

            const [startTime, endTime, status] = slot;

            if (status === 'available' || status === "overtime") {
              if (status === 'available') {
              console.log(startTime > startBookedTime && endBookedTime < endTime)
              const startTimeObj = formatTimeTo24Hour(startTime); 
              const startBookedTimeObj = formatTimeTo24Hour(startBookedTime); 
              const endBookedTimeObj = formatTimeTo24Hour(endBookedTime); 
              const endTimeObj = formatTimeTo24Hour(endTime); 
              console.log((startTimeObj < startBookedTimeObj && endBookedTimeObj < endTimeObj))
              if (startTimeObj < startBookedTimeObj && endBookedTimeObj < endTimeObj) {
                if (restOfTimeAvailable.length > 0) {
                  const [startrestTime, endrestTime, status] = restOfTimeAvailable[0];
                  const startrestTimeObj = formatTimeTo24Hour(startrestTime); 
                  const startBookedTimeObj = formatTimeTo24Hour(startBookedTime); 
                  const endBookedTimeObj = formatTimeTo24Hour(endBookedTime); 
                  const endrestTimeObj = formatTimeTo24Hour(endrestTime); 
                  if (startrestTimeObj < startBookedTimeObj && endBookedTimeObj < endrestTimeObj) {
                    adjustedSlots.push([startrestTime, startBookedTime, status]);
                    adjustedSlots.push(booked);
                  }

                }
                else {
                  adjustedSlots.push([startTime, startBookedTime, status]);
                  adjustedSlots.push(booked);
                }
                
                  restOfTimeAvailable.length = 0;
                  restOfTimeAvailable.push([endBookedTime, endTime, status]);
                }

                

              }
              if (status === 'overtime') {
                console.log(startTime > startBookedTime && endBookedTime < endTime)
                const startTimeObj = formatTimeTo24Hour(startTime); 
                const startBookedTimeObj = formatTimeTo24Hour(startBookedTime); 
                const endBookedTimeObj = formatTimeTo24Hour(endBookedTime); 
                const endTimeObj = formatTimeTo24Hour(endTime); 
                console.log((startTimeObj < startBookedTimeObj && endBookedTimeObj < endTimeObj))
                if (startTimeObj < startBookedTimeObj && endBookedTimeObj < endTimeObj) {
                  if (restOfTimeOver.length > 0) {
                    const [startrestTime, endrestTime, status] = restOfTimeOver[0];
                    const startrestTimeObj = formatTimeTo24Hour(startrestTime); 
                    const startBookedTimeObj = formatTimeTo24Hour(startBookedTime); 
                    const endBookedTimeObj = formatTimeTo24Hour(endBookedTime); 
                    const endrestTimeObj = formatTimeTo24Hour(endrestTime); 
                    if (startrestTimeObj < startBookedTimeObj && endBookedTimeObj < endrestTimeObj) {
                      adjustedSlots.push(booked);
                      adjustedSlots.push([startrestTime, startBookedTime, status]);
                      
                    }
  
                  }
                  else {
                    adjustedSlots.push([startTime, startBookedTime, status]);
                    adjustedSlots.push(booked);
                  }
                restOfTimeOver.length = 0;
                restOfTimeOver.push([endBookedTime, endTime, status]);
              }

            }

            

          }

            lastEndTime = endTime;
          });
        })


        adjustedSlots.push(restOfTimeAvailable[0]);
        adjustedSlots.push(restOfTimeOver[0]);
      }
      else{
        slots.forEach((slot, index) => {
          const [startTime, endTime, status] = slot;
      
          if (status === 'available') {
            if (lastEndTime && lastEndTime !== startTime) {
              adjustedSlots.push([lastEndTime, startTime, 'available']);
            }
            adjustedSlots.push([startTime, endTime, status]);
            lastEndTime = endTime;
          } else {
            adjustedSlots.push([startTime, endTime, status]);
            lastEndTime = endTime;
          }
      
          // Handle the case where the next slot starts after the current one ends
          if (index < slots.length - 1) {
            const nextSlot = slots[index + 1];
            const nextStartTime = nextSlot[0];
            if (endTime !== nextStartTime) {
              adjustedSlots.push([endTime, nextStartTime, 'available']);
            }
          }
        });
      }


        return adjustedSlots.sort((a, b) => {
          const startA = formatTimeTo24Hour(a[0]);
          const startB = formatTimeTo24Hour(b[0]);
          return startA.localeCompare(startB);
        });
      };

      Object.keys(groupedData).forEach(email => {
        groupedData[email].slots = adjustAndSplitTimeSlots(groupedData[email].slots);
      });

      const formattedData = Object.values(groupedData);
      console.log('Données regroupées et formatées pour le calendrier :', formattedData);

      setTimeSlots(formattedData);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du calendrier :', error);
    }
  };




  useEffect(() => {
    const dateString = format(currentDate, 'yyyy-MM-dd');
    
    updateCalendar();
  }, [currentDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    setCurrentTime(new Date());

    return () => clearInterval(interval);
  }, []);

  const calculateCurrentTimeLeft = () => {
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;

    const startTime = 8 * 60;
    const endTime = 21 * 60 + 59;

    
    if (currentTotalMinutes < startTime || currentTotalMinutes > endTime) {
      return null;
    }

    const totalDisplayedMinutes = 840; 
    const elapsedMinutes = currentTotalMinutes - startTime;
    const leftPercentage = (elapsedMinutes / totalDisplayedMinutes) * 100;

    return leftPercentage;
  };

  const currentTimeLeft = calculateCurrentTimeLeft();

  const changeDate = (days) => {
    setCurrentDate((prevDate) => addDays(prevDate, days));
  };

  const renderHourLabels = () => {
    return hours.map((hour, index) => {
      const isHourLabel = index % 60 === 0;

      if (isHourLabel) {
        const [time, modifier] = hour.split(' ');
        const hourOnly = time.split(':')[0];
        const displayHour = `${hourOnly} ${modifier}`;

        return (
          <HourLabel key={index}>{displayHour}</HourLabel>
        );
      } else {

        return (
          <div key={index} style={{ height: '40px', width: '0px' }}></div>
        );
      }
    });
  };

  function convertTimeToMinutes(time) {
    const [hours, minutes] = time
      .match(/(\d+):(\d+)/)
      .slice(1)
      .map(Number); 
    const isPM = time.includes("PM");
    return (hours % 12 + (isPM ? 12 : 0)) * 60 + minutes;
  }
  const formatTimeTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hour, minute] = time.split(':').map(Number);

    if (modifier === 'PM' && hour < 12) hour += 12;
    if (modifier === 'AM' && hour === 12) hour = 0;

    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
  };

  const renderTimeSlots = () => {
    console.log("slottime", timeSlots)
    return timeSlots.flatMap((therapist, tIndex) =>
      therapist.slots.map(([start, end, status], slotIndex) => {
        const startIndex = hours.indexOf(start) + 1;
        const endIndex = hours.indexOf(end) + 1;

        return (
          <TimeSlot
            key={`${tIndex}-${slotIndex}`}
            $status={status}
            style={{ gridColumn: `${startIndex} / span ${endIndex - startIndex}` }}
          >
            <TimeText status={status}>
              {status === 'overtime'
                ? `Overtime ${formatTimeTo24Hour(start)} - ${formatTimeTo24Hour(end)}`
                : status === 'free'
                  ? 'Free Time'
                  : `${formatTimeTo24Hour(start)} - ${formatTimeTo24Hour(end)}`}
            </TimeText>
            <DurationText status={status}>
              {status === 'free' ? '' : `${calculateDuration(start, end)}`}
            </DurationText>
          </TimeSlot>
        );
      })
    );
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search Promotion code"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <div className="select">
              <select>
                <option value="">Region</option>
                <option value="Name">Region</option>
              </select>
            </div>
            <div className="select">
              <select>
                <option value="">Teams</option>
                <option value="Name">Teams</option>
              </select>
            </div>
          </div>
          <div className='actions'>
            <button onClick={handleDownload} className="btn-download">
              Download template
            </button>
            <button onClick={openModal} className="btn-add-client">
              Import
            </button>
          </div>
        </div>
      </div>

      <Header>
        <div className="group-cal">
          <div className="button-cal">
            <div className="iconset-cal">
              <img
                onClick={() => changeDate(-1)}
                className="arrowlineleft-s-icon-cal"
                alt="Previous Day"
                src={arrowlineleft}
              />
            </div>
          </div>
          <div className="text-cal">
            <div className="text1-cal">
              {currentDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}
            </div>
          </div>
          <div className="button-cal">
            <div className="iconset-cal">
              <img
                onClick={() => changeDate(1)}
                className="arrowlineleft-s-icon-cal"
                alt="Next Day"
                src={ArrowLineRight}
              />
            </div>
          </div>
          <div className="button2-cal">
            <div className="text-cal">
              <div className="text1-cal" onClick={() => setCurrentDate(new Date())}>
                Today
              </div>
            </div>
          </div>
        </div>
      </Header>

      <Container>
        <Sidebar>
        {timeSlots.map((therapist, index) => {
          console.log(therapist?.slots)
  const totalAvailableHours = therapist?.slots
    .filter(([start, end, status]) => status === "available" || status === "overtime") 
    .reduce((total, [start, end]) => {
     
      const startMinutes = convertTimeToMinutes(start); 
      const endMinutes = convertTimeToMinutes(end); 

     
      const durationInHours = (endMinutes - startMinutes) / 60;

      return total + durationInHours;
    }, 0)
    .toFixed(2);

    const totalBookedHours = therapist.slots
    .filter(([start, end, status]) => status === "booked") 
    .reduce((total, [start, end]) => {
      const startMinutes = convertTimeToMinutes(start); 
      const endMinutes = convertTimeToMinutes(end); 

      const durationInHours = (endMinutes - startMinutes) / 60;

      
      return total + durationInHours;
    }, 0)
    .toFixed(2);

  return (
    <div key={index} className="list-item-with-image-calendar">
      <img src={image} alt="avatar" className="avatar-img" />
      <div className="therapist-items">
        <div className="title-therapist-item">
          <div className="name-therapist-calendar">
            {therapist.firstname} {therapist.lastname}
          </div>
          <div className="address-therapist-calendar">
            {therapist.zipcode} {therapist.city}
          </div>
        </div>
        <div className="details-therapist-calendar">
          <div className="details-items">
            <p className="availability-therapist">Available: {totalAvailableHours} Hrs</p>
            <p className="availability-therapist">Booked Time: {totalBookedHours} Hrs</p>
            <p className="availability-therapist">
              Availability: {(((parseFloat(totalAvailableHours))/(parseFloat(totalAvailableHours) + parseFloat(totalBookedHours)))*100).toFixed(2)}
              %
            </p>
          </div>
        </div>
      </div>
    </div>
  );
})}

        </Sidebar>

        <CalendarContainer>
          <TimeSlots>
            {renderHourLabels()}
            {renderTimeSlots()}

            {currentTimeLeft !== null && (
              <CurrentTimeWrapper $left={currentTimeLeft}>
                <CurrentTimeIndicatorLine />
                <Tooltip>
                  {format(currentTime, 'hh:mm a')}
                </Tooltip>
              </CurrentTimeWrapper>
            )}
          </TimeSlots>
        </CalendarContainer>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Import Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Import File</h2>
        <div className="modal-content">
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleImport} className="btn-import">
            Upload
          </button>
          <button onClick={closeModal} className="btn-cancel">
            Cancel
          </button>
        </div>
        {message && <div className="message">{message}</div>}
      </Modal>
    </>
  );
};

export default Calendar;